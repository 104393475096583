<script>
import { mapState, mapActions } from "vuex";
import NewsPreview from "@/components/NewsPreview";
import MainButton from "@/components/helpers/MainButton";
import MainLoader from "@/components/helpers/MainLoader.vue";
import PublicActivitiesBanner from "@/components/dashboard/PublicActivitiesBanner.vue";

export default {
  components: {
    PublicActivitiesBanner,
    NewsPreview,
    MainButton,
    MainLoader,
  },

  data() {
    return {
      params: {
        page: 1,
      },
    };
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.loadPublicActivitiesNews(this.params);
      },
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      publicActivitiesNews: (state) => state.news.publicActivitiesNews,
      publicActivitiesNewsStatus: (state) =>
        state.news.publicActivitiesNewsStatus,
      publicActivitiesNewsLastPage: (state) =>
        state.news.publicActivitiesNewsLastPage,
    }),

    isShownTelegramVip() {
      return (
        this.user.tariff?.code === "vip_d" ||
        this.user.tariff?.code === "vip_gold_d"
      );
    },
  },

  methods: {
    ...mapActions({
      loadPublicActivitiesNews: "news/loadPublicActivitiesNews",
    }),
  },

  mounted() {
    this.loadPublicActivitiesNews(this.params);
  },
};
</script>

<template>
  <div class="news">
    <div class="news__banner">
      <PublicActivitiesBanner />
    </div>

    <div class="news__items">
      <NewsPreview
        v-for="news in publicActivitiesNews"
        :key="news.id"
        :news="news"
      />
    </div>

    <MainLoader
      v-if="publicActivitiesNewsStatus === 'LOADING'"
      class="news__loader"
    />

    <MainButton
      v-else-if="params.page < publicActivitiesNewsLastPage"
      class="news__btn"
      :title="'Показать еще'"
      :color="'dark'"
      @click="params.page++"
    />
  </div>
</template>

<style scoped lang="scss">
.news {
  padding: 0 $space-xxl $space-xxl;

  @media (max-width: 576px) and (min-width: 426px) {
    padding: 0 $space-xl $space-xxl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l $space-xl;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m $space-xl;
  }

  &__loader {
    max-width: 694px;
  }

  &__banner {
    max-width: 694px;
    margin-bottom: 32px;
  }

  &__items {
    max-width: 694px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 335px);
    grid-gap: $space-l;

    @media (min-width: 386px) and (max-width: 789px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 385px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  &__btn {
    margin-top: $space-xxl;
    width: 150px;
  }
}

@media (max-width: 475px) {
  .news {
    &__telegram {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
